* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* .home, */
.aboutUs,
/* .companyOverview, */
.leadershipTeam,
.researchAndDevelop,
.ourPresence,
.pipeline,
.careers,
.contactUs,
.signUp {
  /* font-family: "montse", sans-serif; */
  font-family: "Montserrat", sans-serif;

  display: flex;
  height: unset;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  /* margin-top: 80px; */
  height: max-content;
}
/* home css */
/* .home {
  color: #464646;
  font-size: 2rem;
  display: flex;
  background-color: #fff;
} */

/* /animation/ */
@keyframes rotateAtPoint {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slideInFromRightAndFade {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translatey(-200%);
  }
  100% {
    transform: translatey(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translatey(200%);
  }
  100% {
    transform: translatey(0);
  }
}
@keyframes scaleOnPoint {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

/* home css */

/* About Us css  */

/* About Us css  */

/* Research And Devlopment css  */
.researchAndDev {
  background-image: url("./images/img-2.jpg");
  /* background-position: center; */
  /* background-size: cover; */
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.partners {
  background-image: url("./images/img-4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* .careers {
  background-image: url("./images/img-7.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

.ourPresence {
  display: flex;
  color: #0f63ff;
  font-size: 2rem;
}
.ourPresence .Our_Presence_BG {
  background-image: url("./images/undraw_Our_solution_re_8yk6.png");
  /* background-position: center; */
  background-size: 50vw 50vh;
  background-repeat: no-repeat;
  font-size: 100px;
  height: 60vh;
  width: 50%;
}
/* contact us */

/* contact us */

.FullSize {
  margin-top: 80px !important;
}
.Border_Bottom {
  background-image: url("../src/images/borderline.jpg");
  background-size: 100% 18px;
  height: 18px;
  width: 100%;
}

@media screen and (max-width: 780px) {
  .Border_Bottom {
    background-image: url("../src/images/borderline.jpg");
    background-size: 100% 10px;
    height: 10px;
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .FullSize {
    margin-top: 50px !important;
  }
  .Border_Bottom {
    background-image: url("../src/images/borderline.jpg");
    background-size: 100% 5px;
    height: 5px;
    width: 100%;
  }
}
/* tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  visibility: hidden;
  width: 100px;
  background-color: rgb(68, 68, 68);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -50px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(68, 68, 68) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* tooltip */
