.pipeline div .ContactUs_Card {
  display: none !important;
  /* display: flex; */
}
.ContactUs_Card {
  background-color: rgb(248, 232, 214);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.8);
  width: 200px;
  border-radius: 1rem;
  padding: 0.1rem 0.5rem;
  position: fixed;
  bottom: 2rem;
  right: 1rem !important;
  display: flex;
  flex-direction: column;
  z-index: 15;
}
.ContactUs_Card .Card_Question {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ContactUs_Card .Card_Question .Ques {
  color: rgb(7, 99, 122);
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem;
  font-size: 0.8rem;
}
.ContactUs_Card .Card_Question .ContactBTN {
  background-color: rgb(143, 207, 236);
  font-family: "Montserrat", sans-serif;
  padding: 0.2rem 1rem;
  margin: 0.5rem 0.5rem;
  font-size: 1rem;
  border-radius: 1rem;
}
.ContactUs_Card .Card_Question .ContactBTN a {
  text-decoration: none;
  color: black;
}
.ContactUs_Card .OtherProducts div {
  display: flex;
  flex-direction: column;
}
.ContactUs_Card .OtherProducts div .OtherProductsLinkBTN {
  position: relative;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0.2rem 0.1rem;
  border-radius: 1rem;
  border: 1px solid gray;
  background-color: rgb(34, 98, 180);
  color: #fff;
}
.ContactUs_Card .OtherProducts div .OtherProductsLinkBTN .fa-chevron-right {
  position: absolute;
  top: 10px;
  right: 0.5rem;
  display: inline-block;
}
@media screen and (max-width: 780px) {
  .ContactUs_Card {
    width: 120px;
    border-radius: 0.5rem;
    padding: 0.1rem 0.15rem;
    position: fixed;
    bottom: 2rem;
    right: 0.1rem !important;
  }
  .ContactUs_Card .Card_Question .Ques {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN {
    font-size: 0.6rem;
    text-decoration: none;
    padding: 0.2rem 0.5rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN .fa-chevron-right {
    top: 5px;
  }
  .ContactUs_Card .Card_Question .ContactBTN {
    padding: 0.2rem 1rem;
    margin: 0.15rem 0.15rem;
    font-size: 0.6rem;
    border-radius: 1rem;
  }
  .ContactUs_Card .Card_Question .ContactBTN a {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 450px) {
  .ContactUs_Card {
    width: 90px;
  }
  .ContactUs_Card .Card_Question .Ques {
    padding: 0.5rem;
    font-size: 0.5rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN {
    font-size: 0.45rem;
    margin: 0.1rem 0.05rem;
  }
  .ContactUs_Card .OtherProducts div .OtherProductsLinkBTN .fa-chevron-right {
    top: 4px;
  }
  .ContactUs_Card .Card_Question .ContactBTN {
    padding: 0.1rem 0.1rem;
    margin: 0.05rem 0.05rem;
    font-size: 0.5rem;
    border-radius: 1rem;
  }
  .ContactUs_Card .Card_Question .ContactBTN a {
    font-size: 0.5rem;
  }
}
