.home.Dash {
  background-image: url("../../images/Home_Page.jpg");
 
  background-size: 99vw 68vh;
    background-position: initial;
    background-repeat: no-repeat;
    margin-top: 80px;
    animation: backgroundAnimation 30s normal infinite;
    transition: background-image 1s ease;
    height: unset;
    /* width: 70%; */
}
.home {
  /* background-image: url("../../images/Injectible\ Formulation2F.jpg"); */
  background-size: 100vw 90vh;
  background-position: top;
  background-repeat: no-repeat;
  margin-top: 80px;
  height: unset;
  width: 100%;
 
    /* width: 70%; */
}
@keyframes backgroundAnimation {
  0% {
    background-image: url('../../images/Injectible\ Formulation2F.jpg');
  }
  25% {
    /* background-image: url('../../images/Home_Page.jpg'); */
    background-image: url('../../images/DelNovaHomeImage.jpg');
  }
  50% {
      background-image: url('../../images/Landing\ Page.jpeg');
  }
  75% {
    background-image: url('../../images/Home_Page.jpg');
  }
  100% {
      background-image: url('../../images/Injectible\ Formulation2F.jpg');
  }
}
.home .Main.heading {
  background: transparent;
  height: 68.2vh;
}
.home .Main.heading .mainTitle .Primery.Heading {
  font-family: "Montserrat", sans-serif;
  /* padding: 1rem; */
  /* padding-left: 2rem; */
  border-radius: 1rem;
  position: relative;
  color: #081e47;
  color: #fff;
  text-shadow: 2px wheat;
  width: 100%;
  height: 100%;
  line-height: 3.8rem;
  /* font-size: 2.2rem; */
  font-size: 1.6rem;
  font-weight: 900;
}
.home .Main.heading .mainTitle .Primery.Heading .Line1 {
  text-align: left;
  animation: 1.3s ease-out 0s 1 slideInFromRightAndFade;
  font-family: Arial;
  /* padding-top: 4rem; */
  position: relative;
  top: 50px;
  padding-left: 2rem;
}
.home .Main.heading .mainTitle .Primery.Heading .Line1 .Special_wordDiff {
  color: #fff;
  /* font-style: italic; */
}
.home .Main.heading .mainTitle .Primery.Heading .Line1 .Special_wordInno {
  color: #fff;
  /* font-style: italic; */
}

.home .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}

.home .Discription p .newFontTagline {
  font-weight: 750;
  font-style: bold;
  color: #41403f;
  color: #081e47;
}
.Special_In {
  color: red;
  text-decoration: underline;
}

/* ????? */
.home .WhatWeDo {
  font-family: "Montserrat", sans-serif;

  position: relative;
  top: 5%;
  /* left: 5rem; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  color: #313131;
  font-size: 1.4rem;
}
.home .WhatWeDo .AboutUs_WhatWeDo {
  padding-left: 6rem;
  margin-top: 2rem;
  padding-bottom: 3rem;
  font-size: 1.8rem;
}

.home .WhatWeDo .iconList {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-row-gap: 10px;
  color: #0f7fff;
  font-size: 1.8rem;
}
.home .WhatWeDo .iconList li img {
  position: relative;
  top: -12%;
  border: 3px solid rgb(226, 223, 223);
  border-radius: 50%;
  height: 250px;
  width: 250px;
}
.home .WhatWeDo .iconList li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  list-style: none;
  color: #383838;
  margin-left: 0.6rem;
  margin: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}
.home .WhatWeDo .iconList li p {
  width: 95%;
  text-align: center;
  color: #383838;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.3rem;
}
.home .WhatWeDo h4 {
  position: relative;
  color: #083981;
  top: -1%;
  font-size: 2.2rem;
  margin-top: 1.2rem;
  margin-bottom: 1.8rem;
}
.home .PagesCards {
  /* height: 530px; */
  height: 620px;
  margin: 0 2%;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  column-gap: 1%;
  justify-content: space-between;
}
.home .PagesCards .PageCard1,
.home .PagesCards .PageCard2,
.home .PagesCards .PageCard3 {
  /* border: 1px solid black; */
  position: relative;
  padding: 0rem 0rem;
  /* height: 500px; */
  height: 550px;
}
/* .home .PagesCards .PageCard1 {
  background-color: rgb(15, 127, 255, 0.2);
  border-bottom: 10px solid #0f7fff;
}
.home .PagesCards .PageCard1:hover {
  background-color: rgb(15, 127, 255, 0.3);
}
.home .PagesCards .PageCard2 {
  background-color: rgb(6, 158, 145, 0.2);
  border-bottom: 10px solid #069e91;
}
.home .PagesCards .PageCard2:hover {
  background-color: rgb(6, 158, 145, 0.3);
}
.home .PagesCards .PageCard3 {
  background-color: rgb(10, 91, 189, 0.2);
  border-bottom: 10px solid rgb(10, 91, 189);
}
.home .PagesCards .PageCard3:hover {
  background-color: rgb(10, 91, 189, 0.3);
} */
.home .PagesCards .PageCard1 {
  background-color: rgba(148, 45, 41, 0.2);
  border-bottom: 10px solid rgb(148, 45, 41);
}
.home .PagesCards .PageCard1:hover {
  background-color: rgb(148, 45, 41,0.3);
}
.home .PagesCards .PageCard2 {
  background-color: rgb(45, 98, 101,0.2);
  border-bottom: 10px solid #2d6265;
}
.home .PagesCards .PageCard2:hover {
  background-color: rgb(45, 98, 101,0.3);
}
.home .PagesCards .PageCard3 {
  background-color: rgb(235, 127, 37,0.2);
  border-bottom: 10px solid #eb7f25;
}
.home .PagesCards .PageCard3:hover {
  background-color: rgb(235, 127, 37,0.3);
}
.home .PagesCards div .PageCardTitle {
  width: 100%;
  padding: 1.2rem;
  text-align: left;
  font-size: 1.2rem;
  font-family: "Montserrat";
}
.home .PagesCards div .PageCardTitle p {
  width: 85%;
}
.home .PagesCards div .PageCardTitleIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 5px;
  top: 5px;
}
/* .home .PagesCards .PageCard1 .PageCardTitle {
  background-color: rgb(15, 127, 255, 0.6);
}
.home .PagesCards .PageCard2 .PageCardTitle {
  background-color: rgb(6, 158, 145, 0.6);
}
.home .PagesCards .PageCard3 .PageCardTitle {
  background-color: rgba(10, 91, 189, 0.6);
} */
.home .PagesCards .PageCard1 .PageCardTitle {
  background-color:  rgb(148, 45, 41,0.6);
}
.home .PagesCards .PageCard2 .PageCardTitle {
  background-color:  rgb(45, 98, 101,0.6);
}
.home .PagesCards .PageCard3 .PageCardTitle {
  background-color: rgb(235, 127, 37,0.6);
}
.home .PagesCards div .PageDiscriptionShort {
  /* padding: 0.5rem 1.2rem;
  height: 410px;
  font-size: 1rem;
  font-family: "Montserrat";
  line-height: 2.4rem; */
  padding: 0.5rem 1.2rem;
  max-height: 450px;
  height: 400px;
  overflow: auto;
  font-size: 1rem;
  font-family: "Montserrat";
  line-height: 2.4rem;
}
/* .home .PagesCards div a {
  padding: 0.5rem 1.5rem;

  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #0f7fff;
  font-family: "Montserrat";
} */
/* .home .PagesCards div a:hover {
  padding: 0.5rem 1.5rem;

  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #0f7fff;
  font-family: "Montserrat";
} */
.hover-underline-animation {
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0.5rem 2rem 0rem 2rem;
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* responsiveness */
@media screen and (max-width: 1100px) {
  .home .Main.heading {
    background: transparent;
    height: 90vh;
  }
  .home .Discription p {
    line-height: 1.8rem;
    padding: 1.5rem 3rem;
    font-weight: 500;
    font-size: 1rem;
  }
  .home .PagesCards {
    height: 700px;
    grid-template-columns: repeat(3, 32%);
    column-gap: 0.5%;
    margin-top: 2rem;
  }
  .home .PagesCards .PageCard1,
  .home .PagesCards .PageCard2,
  .home .PagesCards .PageCard3 {
    position: relative;
    padding: 0rem 0rem;
    height: 550px;
  }
  .home .Main.heading .mainTitle .Primery.Heading {
    line-height: 3.5rem;
    font-size: 2rem;
    font-weight: 600;
  }
  .home .Main.heading .mainTitle .Primery.Heading .Line1 {
    position: relative;
    top: 25px;
  }
  .home .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.8rem;
    height: 400px;
    font-size: 0.84rem;
    font-family: "Montserrat";
    line-height: 1.8rem;
  }
}
@media screen and (max-width: 780px) {
  .home {
    background-size: 100vw 60vh;
    background-position: top;
    background-repeat: no-repeat;
    margin-top: 80px;
    width: 100%;
  }
  .home .Main.heading {
    background: transparent;
    height: 62vh;
  }
  .home .Discription p {
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.8rem;
  }
  .home .PagesCards {
    height: 1220px;

    margin: 0 5%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    column-gap: 1.5%;
    justify-content: space-between;
  }
  .home .PagesCards .PageCard1,
  .home .PagesCards .PageCard2,
  .home .PagesCards .PageCard3 {
    position: relative;
    padding: 0rem 0rem;
    margin: 1rem;
    height: 380px;
  }
  .home .PagesCards div .PageDiscriptionShort {
    padding: 0.5rem 1.5rem;
    height: 250px;
    font-size: 11px;
    font-family: "Montserrat";
    line-height: 2.5rem;
  }
  .home .Main.heading .mainTitle .Primery.Heading {
    line-height: 2.2rem;
    font-size: 20px;
    font-weight: 600;
  }
  .home .Main.heading .mainTitle .Primery.Heading .Line1 {
    position: relative;
    top: 20px;
  }
  .hover-underline-animation {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 450px) {
  .home {
    background-size: 100vw 40vh;
    background-position: top;
    background-repeat: no-repeat;
    margin-top: 50px;
    width: 100%;
  }
  .home .Main.heading {
    background: transparent;
    height: 40vh;
  }
  .home .Discription p {
    line-height: 1rem;
    padding: 0.5rem 0.5rem;
    font-weight: 500;
    font-size: 0.6rem;
  }
  .home .PagesCards {
    height: 1400px;
    margin: 0 5%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    column-gap: 1.5%;
    justify-content: space-between;
  }
  .home .PagesCards .PageCard1,
  .home .PagesCards .PageCard2,
  .home .PagesCards .PageCard3 {
    position: relative;
    height: 450px;
  }
  .home .PagesCards div .PageDiscriptionShort {
    padding: 0.5rem 1.2rem;
    height: 320px;
    font-size: 0.8rem;
    font-family: "Montserrat";
    line-height: 1.5rem;
  }
  .home .Main.heading .mainTitle .Primery.Heading {
    line-height: 1.5rem;
    font-size: 14px;
    font-weight: 600;
  }
  .home .Main.heading .mainTitle .Primery.Heading .Line1 {
    position: relative;
    top: 15px;
    padding-left: 2rem;
  }
  .home .PagesCards div .PageCardTitle {
    width: 100%;
    padding: 1rem 0.5rem 1rem 1rem;
    text-align: left;
    font-size: 0.8rem;
    font-family: "Montserrat";
  }
  .home .PagesCards div .PageCardTitle p {
    width: 85%;
  }
  .home .PagesCards div .PageCardTitleIcon {
    width: 37px;
    height: 37px;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .hover-underline-animation {
    font-size: 0.8rem;
  }
}
/* /////////// */

/* Awards Slider  */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
.Awards_Highlight {
  font-family: "Montserrat";
  font-family: Montserrat;
  text-align: center;
  color: #000000;
  background: rgba(73, 73, 73, 0.7);
  font-size: 34px;
  padding-top: 2vh;
  padding-bottom: 2vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 80px;
}
.Head {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  /* background-color: #ff9b18; */
  /* z-index: 11; */
  background-color: #f0ff18;
  text-shadow: 4px 3px 3px rgb(184, 184, 184);
  padding: 0.3rem 1rem 0.2rem 1rem;
  border-radius: 0.5rem;
}
.Head {
  animation: appear 6s infinite 2s;
}
.Head:hover {
  animation-play-state: paused;
}
.Body {
  display: inline-block;
  text-align: center;
  animation: reveal 6s infinite 2s;
  padding: 0.2rem 1rem;
  line-height: 2.9rem;
}
.Body:hover {
  animation-play-state: paused;
}
.Body span {
  font-size: 24px;
  text-align: center;

  color: #ffffff;
  animation: slide 6s infinite 2s;
}
.Body span:hover {
  animation-play-state: paused;
}
.Body span .Go_To_moreAboutAwards {
  position: relative;
  top: -2px;
  font-size: 1rem;
  color: #000000;
  background-color: #37bcff;
  border: 1px solid #005783;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
.Body span .Go_To_moreAboutAwards:hover {
  color: #ffffff;
  background-color: #0096e0;
  border: 1px solid #024263;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  animation-play-state: paused;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide {
  0% {
    margin-left: -800px;
  }
  20% {
    margin-left: -800px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 0;
    width: 0px;
  }
  30% {
    opacity: 0.8;
    width: 680px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 680px;
  }
}
.Awards_Highlight:before {
  content: "";
  position: absolute;
  top: 150px;
  left: -300px;
  width: 150px;
  height: 160px;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  animation-name: slide;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-delay: 2.5s;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 0.05) 20%,
    rgba(255, 255, 255, 0.08) 30%,
    rgba(255, 255, 255, 0.13) 50%,
    rgba(255, 255, 255, 0.23) 70%,
    rgba(255, 255, 255, 0.3) 77%,
    rgba(255, 255, 255, 0.6) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
@keyframes slide {
  0% {
    left: -100px;
    top: 0;
  }

  80% {
    left: 75%;
    top: 0;
  }

  /* 60% {
    left: 77%;
    top: 0;
  } */
  /* 70% {
    left: 80%;
    top: 0;
  } */
  /* 80% {
    left: 82%;
    top: 0;
  }
  90% {
    left: 85%;
    top: 0;
  } */

  100% {
    left: 100%;
    top: 0;
  }
}
/* Awards Slider  */
/* Awards section  */

.More_About_Award {
  margin: 2rem 4rem;
  display: flex;
  justify-content: space-between;
}
.More_About_Award .Award_Image img {
  height: 50vh;
  width: 35vw;
}
.More_About_Award .Award_Description {
  padding: 2rem;
  width: 50vw;
}
.More_About_Award .Award_Description .Award_Description_Head {
  font-size: 1.6rem;
  font-family: "Montserrat";
}
.More_About_Award .Award_Description .Award_Description_Body {
  font-size: 1.2rem;
  font-family: "Montserrat";
  margin-top: 2rem;
}
/* Awards section  */
/* Innovative */
.home.otherProduct {
  background-image: none;
}
.home.otherProduct .LinkToDelNova {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home.otherProduct .LinkToDelNova img {
  height: 110px;
  width: 190px;
  margin: 15px 50px;
  padding: 10px;
}
.home.otherProduct .LinkToDelNova img:hover {
  cursor: pointer;
  background-color: #ececec;
  border-radius: 15px;
}
.home.otherProduct .LinkToDelNova .RedirectMSG {
  font-family: "Montserrat", sans-serif;
  border-radius: 1rem;
  position: relative;
  color: #081e47;
  text-shadow: 2px wheat;
  height: 100%;
  line-height: 4.8rem;
  font-size: 2.0rem;
  font-weight: 900;
}
/* Innovative */

