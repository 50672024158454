.ApplyHere {
  margin-top: 80px;
}
.ApplyHere .HHeading {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  margin-top: 5rem;
}
.ApplyHere .heading {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: gray;
  margin: 1rem;
  font-size: 1.2rem;
}
.ApplyHere .ApplyHereForm {
  margin: 1rem auto;
  background-color: rgb(240, 240, 240);
  border-radius: 1rem;
  display: flex;
  width: 600px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ApplyHere .ApplyHereForm label {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 1.2rem;
  margin: 0.8rem 2rem 0.2rem;
  width: 300px;
  /* position: relative; */
  /* left: -75px; */
}
.ApplyHere .ApplyHereForm a {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 1.2rem;
  margin: 0rem 2rem 0.2rem;
  width: 300px;
  text-decoration: none;
  color: rgb(0, 110, 161);
}
.ApplyHere .ApplyHereForm a:hover {
  color: rgb(15, 39, 179);
}
.ApplyHere .ApplyHereForm input {
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 1.2rem;
  border-radius: 0.2rem;
  border: 1px solid gray;
  /* margin: 0.5rem 1rem; */
  padding: 0.5rem;
  width: 300px;
  height: 2.2rem;
  position: relative;
  /* left: -20%; */
}
.ApplyHere .ApplyHereForm input[type="file"] {
  padding: 0.15rem;
}
.ApplyHere .ApplyHereForm input[type="submit"] {
  text-align: center;
  font-size: 1.2rem;
  height: fit-content;
  border-radius: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: rgb(42, 147, 218);
  color: #fff;
  padding: 0.5rem 1.5rem;
}
.ApplyHere .ApplyHereForm input[type="submit"]:hover {
  background-color: rgb(1, 121, 201);
  color: #fff;
  transform: scale(0.98);
}
