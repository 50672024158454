* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer {
  background-color: #cfcfcf;
  position: relative;
  display: flex;
  color: rgb(53, 34, 0);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.01rem;
}
.footer .SocialMediaLinks {
  margin: 0.5rem 0.5rem;
  display: flex;
  justify-content: space-evenly;
}
.footer .SocialMediaLinks a img {
  margin: 0 1.2rem;
  height: 30px;
  width: 30px;
}
.footer .SocialMediaLinks {
  display: flex;
}
.footer .DunsTradeMark {
  position: absolute;
  left: 1rem;
}
.footer .DunsTradeMark a:-webkit-any-link {
  color: #3d2201 !important;
  text-decoration: none !important;
  cursor: pointer;
}

.footer .Centered {
  display: flex;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  font-size: 1.4rem;

  justify-content: center;
}
.footer p {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;

  justify-content: center;
}
.Centered p {
  margin: 0;
  line-height: 1rem;
}
.footer .DunsLogo iframe {
  position: absolute;
  /* height: 50px; */
  /* width: 50px; */
  top: 0%;
  left: 0vw;
}
@media screen and (max-width: 780px) {
  .footer {
    margin-top: 5rem;

    position: relative;
    bottom: 0;
  }
  .footer .SocialMediaLinks {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .footer .SocialMediaLinks a img {
    height: 20px;
    width: 20px;
  }
  .footer p {
    font-size: 0.8rem;
  }
  .footer .DunsLogo iframe {
    position: absolute;
    top: 0%;
    left: 0vw;
  }
}
@media screen and (max-width: 450px) {
  .footer .SocialMediaLinks a img {
    height: 15px;
    width: 15px;
  }
  .footer p {
    font-size: 0.6rem;
  }
  .footer .DunsLogo iframe {
    position: absolute;
    top: 0%;
    left: 0vw;
  }
}
