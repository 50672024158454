.researchAndDevelop .ResearchAndDevelopPage {
  display: flex;
  flex-direction: column;
  /* margin: 1rem 1.5rem; */
  /* margin-top: 0; */
  background-color: #fff;
  width: 100%;
  height: fit-content;
}
.researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
  /* background-image: url("../../images/images\ 2.jpeg"); */
  /* background-size: 100vw 100vh; */
  /* background-position: center bottom; */
  /* background-repeat: no-repeat; */
  /* width: 100%; */
  /* margin: 0 5%; */
  height: 12vh;
}
.researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
  /* position: relative; */
  /* top: 30px; */
  width: 100%;
  font-size: 1.6rem;
  font-family: Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  /* animation: 1s ease-out 0s 1 slideInFromRight; */
  color: rgb(1, 42, 95) !important;
  line-height: 2.2rem;
  background: transparent;
  padding: 2.5rem;
}
.researchAndDevelop .ResearchAndDevelopPage .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}
.researchAndDevelop .ResearchAndDevelopPage .Discription p .newFontTagline {
  font-weight: 750;
  font-style: bold;
  color: #081e47;
}

.ResearchAndDevelopPage .PagesCards {
  height: 570px;
  margin: 1rem 1.5rem;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 24%);
  column-gap: 1%;
  justify-content: space-between;
}
.ResearchAndDevelopPage .PagesCards .PageCard1,
.ResearchAndDevelopPage .PagesCards .PageCard2,
.ResearchAndDevelopPage .PagesCards .PageCard3,
.ResearchAndDevelopPage .PagesCards .PageCard4 {
  position: relative;
  padding: 0rem 0rem;
  height: 550px;
}

.ResearchAndDevelopPage .PagesCards .PageCard1 {
  background-color: rgb(15, 127, 255, 0.2);
  border-bottom: 10px solid #0f7fff;
}
.ResearchAndDevelopPage .PagesCards .PageCard1:hover {
  background-color: rgb(15, 127, 255, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard2 {
  background-color: rgb(6, 158, 145, 0.2);
  border-bottom: 10px solid #069e91;
}
.ResearchAndDevelopPage .PagesCards .PageCard2:hover {
  background-color: rgb(6, 158, 145, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard3 {
  background-color: rgb(10, 91, 189, 0.2);
  border-bottom: 10px solid #0a3f80;
}
.ResearchAndDevelopPage .PagesCards .PageCard3:hover {
  background-color: rgba(10, 91, 189, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard4 {
  background-color: rgba(96, 49, 170, 0.2);
  border-bottom: 10px solid #6031aa;
}
.ResearchAndDevelopPage .PagesCards .PageCard4:hover {
  background-color: rgba(96, 49, 170, 0.4);
}
.ResearchAndDevelopPage .PagesCards .PageCard1 .PageCardTitle {
  background-color: rgb(15, 127, 255, 0.6);
}
.ResearchAndDevelopPage .PagesCards .PageCard2 .PageCardTitle {
  background-color: rgb(6, 158, 145, 0.6);
}
.ResearchAndDevelopPage .PagesCards .PageCard3 .PageCardTitle {
  background-color: rgba(10, 91, 189, 0.6);
}
.ResearchAndDevelopPage .PagesCards .PageCard4 .PageCardTitle {
  background-color: rgba(96, 49, 170, 0.6);
}
.ResearchAndDevelopPage .PagesCards div .PageCardTitle {
  width: 100%;
  padding: 1.2rem;
  text-align: left;
  font-size: 1rem;
  font-family: "Montserrat";
}
.ResearchAndDevelopPage .PagesCards div .PageCardTitleIcon {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
  padding: 0.5rem 1rem;
  height: 400px;
  /* overflow-y: scroll; */
  /* max-height: fit-content; */
  max-height: 450px;
  overflow: auto;
  font-size: 0.8rem;
  font-family: "Montserrat";
  line-height: 2rem;
}
/* .ResearchAndDevelopPage .PagesCards div a {
 
  text-decoration: none;
  color: #0f7fff;
} */
/* .ResearchAndDevelopPage .PagesCards div a:hover {
  text-decoration: none;
} */
.ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation,
.PageLink.redirectToRiBHUS a span {
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0.5rem 1rem 0rem 1rem;
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after,
.PageLink.redirectToRiBHUS a span .hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}
.hover-underline-animation:hover:after,
.PageLink.redirectToRiBHUS a span .hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
@media screen and (max-width: 1100px) {
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
    /* background-size: 100vw 100vh; */
    height: 20vh;
  }
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  .researchAndDevelop .ResearchAndDevelopPage .Discription p {
    line-height: 2rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
  .ResearchAndDevelopPage .PagesCards {
    height: 730px;
    margin: 1rem 1.5rem;
    grid-template-columns: repeat(4, 24.5%);
    column-gap: 0.5%;
  }
  .ResearchAndDevelopPage .PagesCards .PageCard1,
  .ResearchAndDevelopPage .PagesCards .PageCard2,
  .ResearchAndDevelopPage .PagesCards .PageCard3,
  .ResearchAndDevelopPage .PagesCards .PageCard4 {
    margin: 0.1rem;
    height: 700px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageCardTitle {
    padding: 1rem;
    font-size: 1rem;
    font-family: "Montserrat";
  }
  .ResearchAndDevelopPage .PagesCards div .PageCardTitleIcon {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.5rem;
    height: 570px;
    font-size: 0.9rem;
    line-height: 1.8rem;
  }
  .ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation {
    font-size: 1rem;
  }
}
@media screen and (max-width: 780px) {
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
    /* background-size: 100vw 80vh; */
    height: 15vh;
  }
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 0.5rem;
  }
  .researchAndDevelop .ResearchAndDevelopPage .Discription p {
    line-height: 1.8rem;
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
  }
  .ResearchAndDevelopPage .PagesCards {
    height: 1400px;
    margin: 1rem 1.5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }
  .ResearchAndDevelopPage .PagesCards .PageCard1,
  .ResearchAndDevelopPage .PagesCards .PageCard2,
  .ResearchAndDevelopPage .PagesCards .PageCard3,
  .ResearchAndDevelopPage .PagesCards .PageCard4 {
    margin: 0.5rem;
    height: 350px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.5rem;
    height: 220px;
    font-size: 0.9rem;
    line-height: 2rem;
  }
  .ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation {
    font-size: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG {
    /* background-size: 100vw 60vh; */
    height: 15vh;
  }
  .researchAndDevelop .ResearchAndDevelopPage .ResearchAndDevelopBG .HHeading {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 1.5rem;
  }
  .researchAndDevelop .ResearchAndDevelopPage .Discription p {
    line-height: 1.4rem;
    padding: 0.2rem 0.5rem;
    font-size: 0.6rem;
  }
  .ResearchAndDevelopPage .PagesCards {
    height: 1700px;
    margin: 1rem 1.5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }
  .ResearchAndDevelopPage .PagesCards .PageCard1,
  .ResearchAndDevelopPage .PagesCards .PageCard2,
  .ResearchAndDevelopPage .PagesCards .PageCard3,
  .ResearchAndDevelopPage .PagesCards .PageCard4 {
    margin: 0.5rem;
    height: 400px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageCardTitleIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .ResearchAndDevelopPage .PagesCards div .PageDiscriptionShort {
    padding: 0.2rem 0.5rem;
    height: 265px;
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .ResearchAndDevelopPage .PagesCards div .PageLink .hover-underline-animation {
    font-size: 0.8rem;
  }
}
.PageLink.redirectToRiBHUS a img {
  position: relative;
  bottom: 80px;
  width: 200px;
  height: 100px;
  padding: 10px;
  margin-left: 0.5rem;
}

.PageLink.redirectToRiBHUS a img:hover {
  background-color: #069e915a;
  border-radius: 1rem;
}
