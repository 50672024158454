.Contact_Us_Form {
  position: relative;
  top: 80px;
  left: 20px;
  right: 10px;
  background-color: #fff;
  border-radius: 1rem;
  width: 35%;
  padding: 0rem 0.5rem;
  height: fit-content;
}
.Contact_Us_Form .Form {
  padding: 0.1rem 1.5rem 2.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.Contact_Us_Form .HHeading {
  font-family: "Montserrat", sans-serif;
  color: rgb(69, 69, 70);
  font-size: 1.8rem;
  margin: 10px;
  margin-top: 20px;
  margin-left: 1.6rem;
}
.Contact_Us_Form .Contact_Us_Form_Inputs {
  width: 100%;
}
.Contact_Us_Form .Contact_Us_Form_Inputs label {
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  font-size: 1rem;
  font-weight: 500;
  display: block;
}
.Contact_Us_Form .Contact_Us_Form_Inputs input,
.Contact_Us_Form .Contact_Us_Form_Inputs .Message_contactForm {
  width: 100%;
  height: 2rem;
  margin-left: 3px;
  padding-left: 8px;
  font-size: 1.2rem;
  color: rgb(43, 43, 43);
  border-radius: 5px;
  border: 0.8px solid gray;
  border-bottom: 3px solid gray;
  border-right: 2px solid gray;
  margin-bottom: 7px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.Contact_Us_Form .Contact_Us_Form_Inputs textarea {
  height: 6rem !important;
  padding-top: 3px;
}
.Message_contactForm {
  height: 5rem;
  width: 90%;
}
.Contact_Us_Form .Form input[type="submit"] {
  text-align: center;
  background-color: #0270ca;
  color: rgb(228, 230, 231);
  padding: 5px;
  margin-top: 1rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  width: 30%;
}
.Contact_Us_Form .Form input[type="submit"]:hover {
  text-align: center;
  color: rgb(255, 255, 255);
  width: 30%;
  transform: scale(0.97);
}
@media screen and (max-width: 1100px) {
  .Contact_Us_Form {
    position: relative;
    top: 80px;
    left: 0;

    right: 10px;
    background-color: #fff;
    border-radius: 1rem;
    width: 40%;
    padding: 0rem 0.5rem;
    height: fit-content;
  }
  .Contact_Us_Form .Form input[type="submit"] {
    padding: 5px;
    border-radius: 0.5rem;
    font-size: 1rem;
    width: 50%;
  }
}
@media screen and (max-width: 780px) {
  .Contact_Us_Form {
    position: relative;
    top: 0;
    right: 0;
    width: 70%;
    padding: 0rem;
    margin: 0rem 15%;
    height: fit-content;
  }
  .Contact_Us_Form .Form input[type="submit"] {
    padding: 5px;
    border-radius: 1rem;
    font-size: 1.2rem;
    width: 40%;
  }
}
@media screen and (max-width: 450px) {
  .Contact_Us_Form {
    position: relative;
    top: 0;
    right: 0;
    width: 90%;
    padding: 0rem;
    margin: 0rem 5%;
    height: fit-content;
  }
  .Contact_Us_Form .Form input[type="submit"] {
    padding: 5px;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    width: 100%;
  }
}
