.dropdown-menu {
  width: fit-content;
  position: absolute;
  top: 60px;
  list-style: none;
  text-align: start;
  /* border-radius: 20px; */
  border-top: 3px solid #0756a0;

  background: linear-gradient(180deg, #ffffff 0%, #cacaca 100%);
  /* background: radial-gradient(
    #ffffff 0%,
    #eeeded 30%,
    #f0f0f0 70%,
    #bebdbd 100%
  ); */
}
.dropdown-menu li {
  font-size: 16px;
  color: #126bc1;
  cursor: pointer;
}
.dropdown-menu.checked {
  display: none;
}
.dropdown-link {
  font-weight: 600;
  transition: ease-in;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: rgb(8, 63, 116);
  padding: 12px 16px;
  border-radius: 10px;
}
.dropdown-link:hover {
  transition: ease-in;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: rgb(93, 165, 233);
  padding: 12px 16px;
  border-radius: 10px;
}
