.ourPresence {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}

.ourPresence .Our_Presence_Bg {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 100%;
  position: relative;
  background-image: url("../../images/OurPresence2.png");
  background-size: 100% 400px;
  background-position: right;
  background-repeat: no-repeat;
  display: none;
}
.ourPresence .Our_Presence_Bg .HHeading {
  position: relative;
  width: 90%;
  top: 42%;
  color: rgb(27, 49, 97);
}
.ourPresence .Discription p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding: 2rem 5rem;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: #41403f;
  color: #081e47;
}
.ourPresence .Discription p span {
  font-weight: 750;
  font-style: italic;
  color: #41403f;
  color: #081e47;
}
.ourPresence h1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  color: rgb(0, 29, 71);
}
.ourPresence .OurPresenceMap {
  height: 70vh;
  width: 90%;
  background-image: url("../../images/Our\ Presence\ Map.png");
  background-size: 90% 70vh;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}
.carousel .carousel-status {
  display: none;
}
.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
  height: 100px;
  width: 150px !important;
}
.OurPresenceCarousel .carousel-root {
  height: 70vh;
  width: 65vw;
  margin-bottom: 5rem;
}
.OurPresenceCarousel .carousel-root div img {
  height: 70vh;
  width: 100%;
}
@media screen and (max-width: 1100px) {
  .ourPresence .Our_Presence_Bg {
    height: 30vw;
    background-size: 100% 30vw;
  }
  .ourPresence .Discription p {
    line-height: 1.8rem;
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
  }
  .ourPresence h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 780px) {
  .ourPresence .Our_Presence_Bg .HHeading {
    font-size: 2rem;
  }
  .ourPresence .Discription p {
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  .ourPresence h1 {
    font-size: 1.8rem;
  }
  .ourPresence .OurPresenceMap {
    height: 50vh;
    width: 90%;
    background-size: 90% 50vh;
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 450px) {
  .ourPresence .Our_Presence_Bg .HHeading {
    font-size: 1.2rem;
  }
  .ourPresence .Discription p {
    line-height: 1.2rem;
    padding: 0.5rem 1rem;
    font-size: 0.6rem;
  }
  .ourPresence h1 {
    font-size: 1.2rem;
  }
  .ourPresence .OurPresenceMap {
    height: 26vh;
    width: 90%;
    background-size: 90% 26vh;
    margin-bottom: 0rem;
  }
}
