.companyOverview {
  /* border-bottom: 2px solid #754c17; */
  width: 100%;
}
.companyOverview .HHeading {
  /* font-size: 2.4rem; */
  padding-left: 0;
}
.companyOverviewPage .WhoWeAre {
  background-color: rgb(147, 188, 241);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 70%;
  color: #313131;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.companyOverviewPage .WhoWeAre h4 {
  width: 80%;
  color: #313131;
  font-size: 1.8rem;
  margin-left: 4rem;
  margin-right: 2rem;
  /* border-bottom: 2px solid rgb(134, 88, 18); */
  padding-bottom: 2rem;
}
.companyOverviewPage .WhoWeAre p {
  text-align: justify;
  width: 90%;
  line-height: 2.5rem;
  color: #313131;
  font-size: 1.2rem;
  margin-left: 4rem;
  margin-right: 4rem;
  padding-bottom: 2rem;
}
.companyOverviewPage .WhoWeAre p .newFontTagline {
  font-weight: 750;
  font-style: bold;
  color: #41403f;
  color: #081e47;
}
@media screen and (max-width: 1100px) {
  .companyOverviewPage .WhoWeAre {
    width: 100%;
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .companyOverviewPage .WhoWeAre h4 {
    width: 100%;
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-right: 1rem;
  }
  .companyOverviewPage .WhoWeAre p {
    text-align: justify;
    width: 100%;
    line-height: 2.2rem;
    font-size: 16px;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 780px) {
  .companyOverviewPage .WhoWeAre {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .companyOverviewPage .WhoWeAre h4 {
    font-size: 1.4rem;
  }
  .companyOverviewPage .WhoWeAre p {
    line-height: 1.5rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 450px) {
  .companyOverviewPage .WhoWeAre {
    width: 100%;
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .companyOverviewPage .WhoWeAre h4 {
    width: 100%;
    font-size: 1.2rem;
  }
  .companyOverviewPage .WhoWeAre p {
    line-height: 1.2rem;
    font-size: 0.8rem;
  }
}
